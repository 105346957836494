import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FTUTrans } from 'components/FTUComponent';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { genNoCol } from 'components/category/Datatable';
import { AuthenticationService } from '../services/access/AuthenticationService';
import { toast } from 'react-hot-toast';
import IconSVG from '../containers/app/IconSVG';
import Datatable from './category/Datatable';
let counter = 0;
let countDown;

export const showWarningToaster = async (message) => {
    toast(message + '', {
        duration: 4000,
        position: 'top-center',

        // Styling
        style: {},
        className: '',

        // Custom Icon
        icon: IconSVG.WariningSVG,

        // Change colors of success/error/loading icon
        iconTheme: {
            primary: '#000',
            secondary: '#fff',
        },
    });
};

export const showSuccessToaster = async (message) => {
    toast(message, {
        duration: 4000,
        position: 'top-center',

        // Styling
        style: {},
        className: '',

        // Custom Icon
        icon: IconSVG.SuccessSVG,

        // Change colors of success/error/loading icon
        iconTheme: {
            primary: '#000',
            secondary: '#fff',
        },
    });
};

export var showMessageBox = async function (
    _message,
    _title = <FTUTrans ns='common' name='msg.title' />,
    _func,
    _showConfirmBtn = false,
    _confirmButtonTitle = undefined
) {
    // console.log(_message,
    //     _title,
    //     _func)
    await this.setState({
        open: true,
        color: 'modal-primary',
        showConfirmBtn: _showConfirmBtn,
        title: _title,
        message: _message,
        onClose: _func,
    });
    let focus = setInterval(() => {
        if (document.getElementById('btnCloseMessage')) {
            document.getElementById('btnCloseMessage').focus();
        } else {
            clearInterval(focus);
        }
    }, 5);
    if (_confirmButtonTitle) {
        document.getElementById('btnConfirmYes').innerHTML = _confirmButtonTitle;
    }
};

export var showSuccessBox = async function (
    _message,
    _title = <FTUTrans ns='common' name='msg.title' />,
    _func
) {
    // console.log(_message,
    //     _title,
    //     _func)
    await this.setState({
        open: true,
        color: 'modal-success',
        showConfirmBtn: false,
        title: _title,
        message: _message,
        onClose: _func,
    });
    let focus = setInterval(() => {
        if (document.getElementById('btnCloseMessage')) {
            document.getElementById('btnCloseMessage').focus();
        } else {
            clearInterval(focus);
        }
    }, 5);
};

export var showErrorBox = async function (
    _message,
    _title = <FTUTrans ns='common' name='msg.title' />,
    _func
) {
    console.log("showErrorBox _message: ", _message);
   try {
    if (_message.includes("Cannot read properties of")) {
     _message = _message.replace("Cannot read properties of", <FTUTrans ns='common' name='msg.title' />)
    }
   } catch (error) {
    
   }
    await this.setState({
        open: true,
        color: 'modal-danger',
        showConfirmBtn: false,
        title: _title,
        message: _message,
        onClose: _func,
    });
    let focus = setInterval(() => {
        if (document.getElementById('btnCloseMessage')) {
            document.getElementById('btnCloseMessage').focus();
        } else {
            clearInterval(focus);
        }
    }, 5);
};

export var showWarningBox = async function (
    _message,
    _title = <FTUTrans ns='common' name='msg.title' />,
    _showConfirmBtn = false,
    _confirmButtonTitle = undefined,
    _onConfirm = () => {}
) {
    // let audio = new Audio(
    //  'https://admin-s3.s3-sgn09.fptcloud.com/employee/2024/6/28/Windows%2010%20Error%20Sound.mp3'
    // );
    // audio.play();
    // console.log(_message,
    //     _title)
    await this.setState({
        open: true,
        color: 'modal-warning',
        showConfirmBtn: _showConfirmBtn,
        title: _title,
        message: _message,
        onConfirm: _onConfirm,
    });
    let focus = setInterval(() => {
        if (document.getElementById('btnCloseMessage')) {
            document.getElementById('btnCloseMessage').focus();
        } else {
            clearInterval(focus);
        }
    }, 5);
    if (_confirmButtonTitle) {
        document.getElementById('btnConfirmYes').innerHTML = _confirmButtonTitle;
    }
};

export var showConfirm = async function (
    _message,
    _onConfirm,
    _title = <FTUTrans ns='common' name='msg.confirm' />
) {
    // console.log(_message,
    //     _title)
    await this.setState({
        open: true,
        color: 'modal-primary',
        showConfirmBtn: true,
        title: _title,
        message: _message,
        onConfirm: _onConfirm,
    });
    document.getElementById('btnConfirmYes').focus();
};
export var showMessageBoxCountDown = function (
    _message,
    _timeCountDown,
    _onConfirm,
    _onNonConfirm,
    _isLogout,
    _title = <FTUTrans ns='common' name='msg.title' />
) {
    this.setState({
        open: true,
        color: 'modal-primary',
        showConfirmBtn: true,
        title: _title,
        message: _message,
        onConfirm: _onConfirm,
        onClose: _onNonConfirm,
        isCountDown: true,
        isLogout: _isLogout,
    });
    counter = 0;
    countDown = undefined;
    this.timeDown = undefined;
    if (this.state.isCountDown) {
        this.countDownFc(_timeCountDown);
    }
};

export var showMessageBoxIdleLogout = function (waitingTime) {
    this.idleLogout(waitingTime);
};

export var genStatusCol = (data, column) => {
    // !!dang do
    // 
    // 
    // data.status = "Math.floor(Math.random() * 2)";
    return (
        <div className={'flex-container'}>
            <span
                className={`fa ${
                    data.status == 'SUCCESS' ? 'fa-check text-success' : 'fa-times text-danger'
                }`}
            ></span>
        </div>
    );
};

/**========================================================================
 **                           resErrorTable
 *?  Trở về bảng lỗi trong trường hợp res.data.data ko rỗng
 *========================================================================**/
export var resErrorTable = (data) => {
    return (
        <div className='popup-table'>
            <Datatable className='flex-100-row' value={data} paginator={false}>
                <Column
                    header={'STT'}
                    style={{
                        width: '15%',
                        textAlign: 'center',
                    }}
                    body={genNoCol}
                />
                <Column
                    field='message'
                    header={'Message'}
                    style={{
                        width: '60%',
                        textAlign: 'center',
                    }}
                />
                <Column
                    field='status'
                    body={genStatusCol}
                    header={'Trạng thái'}
                    style={{
                        width: 'auto',
                        textAlign: 'center',
                    }}
                />
            </Datatable>
        </div>
    );
};

export var messageboxDataTable = (data) => {
    var columns = <></>;
    if (data?.length > 0) {
        columns = [
            <Column
                header={'STT'}
                style={{
                    width: '15%',
                    textAlign: 'center',
                }}
                body={genNoCol}
            />,
        ];
        
        for (let columnName in Object.keys(data[0])) {
            columnName = Object.keys(data[0])[columnName];
            
            columns.push(
                <Column
                    field={columnName}
                    header={<FTUTrans ns='common' name={`data.${columnName}`} />}
                    style={{
                        width: '60%',
                        textAlign: 'center',
                    }}
                />
            );
        }
    }
    
    const returnTable = (
        <Datatable
            className='flex-100-row'
            value={data}
            paginator={false}
            // style={{ width: '90vw' }}
        >
            {columns}
        </Datatable>
    );
    
    return returnTable;
};

export const MessageBoxSvg = (
    <svg xmlns='http://www.w3.org/2000/svg' width='54' height='54' viewBox='0 0 54 54' fill='none'>
        <g clip-path='url(#clip0_23410_98359)'>
            <path
                d='M27.0007 53.6673C41.7282 53.6673 53.6673 41.7282 53.6673 27.0007C53.6673 12.2731 41.7282 0.333984 27.0007 0.333984C12.2731 0.333984 0.333984 12.2731 0.333984 27.0007C0.333984 41.7282 12.2731 53.6673 27.0007 53.6673Z'
                fill='#00AEEF'
            />
            <path
                d='M27.4722 39.5605C30.2287 39.5605 30.7379 36.9518 30.5849 34.2645L30.1642 24.7543C29.5677 21.7827 25.3752 21.7898 24.78 24.7543L24.3594 34.2645C24.2062 37.1637 24.7148 39.5605 27.4722 39.5605Z'
                fill='white'
            />
            <path
                d='M27.4712 19.8615C29.2039 19.8615 30.6085 18.475 30.6085 16.7647C30.6085 15.0544 29.2039 13.668 27.4712 13.668C25.7386 13.668 24.334 15.0544 24.334 16.7647C24.334 18.475 25.7386 19.8615 27.4712 19.8615Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_23410_98359'>
                <rect
                    width='53.3333'
                    height='53.3333'
                    fill='white'
                    transform='translate(0.333984 0.333984)'
                />
            </clipPath>
        </defs>
    </svg>
);

export const MessageSuccessSvg = (
    <svg xmlns='http://www.w3.org/2000/svg' width='54' height='54' viewBox='0 0 54 54' fill='none'>
        <g clip-path='url(#clip0_23410_97216)'>
            <path
                d='M27.0007 53.6673C41.7282 53.6673 53.6673 41.7282 53.6673 27.0007C53.6673 12.2731 41.7282 0.333984 27.0007 0.333984C12.2731 0.333984 0.333984 12.2731 0.333984 27.0007C0.333984 41.7282 12.2731 53.6673 27.0007 53.6673Z'
                fill='#2EB553'
            />
            <path
                d='M24.8602 35C24.154 35 23.448 34.719 22.9089 34.157L17.2222 28.228C16.1441 27.1045 15.9928 25.4569 17.0709 24.3333C18.149 23.2093 19.9364 23.2093 21.0145 24.3333L24.8602 29.112L32.9533 19.843C34.0314 18.719 35.9497 18.719 37.0278 19.843C38.1059 20.9665 37.7161 22.7883 36.638 23.9119L26.8115 34.157C26.2726 34.719 25.5664 35 24.8602 35Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_23410_97216'>
                <rect
                    width='53.3333'
                    height='53.3333'
                    fill='white'
                    transform='translate(0.333984 0.333984)'
                />
            </clipPath>
        </defs>
    </svg>
);

export const MessageErrorSvg = (
    <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'>
        <path
            d='M31.9996 8C34.5201 7.99987 37.0402 9.25884 38.517 11.7734L57.5659 44.205C60.616 49.3983 56.9715 56 51.0497 56H12.952C7.03376 56 3.38386 49.4014 6.43552 44.205L25.4846 11.7734C26.9628 9.25679 29.4814 8.00013 31.9996 8Z'
            fill='#F5222D'
        />
        <path
            d='M32.0004 20.3867C29.244 20.3867 28.7347 22.9954 28.8878 25.6828L29.3084 35.193C29.905 38.1645 34.0974 38.1574 34.6926 35.193L35.1133 25.6828C35.2664 22.7836 34.7579 20.3867 32.0004 20.3867Z'
            fill='white'
        />
        <path
            d='M32.0005 46.7111C33.7332 46.7111 35.1378 45.3247 35.1378 43.6144C35.1378 41.9041 33.7332 40.5176 32.0005 40.5176C30.2679 40.5176 28.8633 41.9041 28.8633 43.6144C28.8633 45.3247 30.2679 46.7111 32.0005 46.7111Z'
            fill='white'
        />
    </svg>
);
export const MessageWarningSvg = (
    <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'>
        <path
            d='M31.9996 8C34.5201 7.99987 37.0402 9.25884 38.517 11.7734L57.5659 44.205C60.616 49.3983 56.9715 56 51.0497 56H12.952C7.03376 56 3.38386 49.4014 6.43552 44.205L25.4846 11.7734C26.9628 9.25679 29.4814 8.00013 31.9996 8Z'
            fill='#ffcc00'
        />
        <path
            d='M32.0004 20.3867C29.244 20.3867 28.7347 22.9954 28.8878 25.6828L29.3084 35.193C29.905 38.1645 34.0974 38.1574 34.6926 35.193L35.1133 25.6828C35.2664 22.7836 34.7579 20.3867 32.0004 20.3867Z'
            fill='white'
        />
        <path
            d='M32.0005 46.7111C33.7332 46.7111 35.1378 45.3247 35.1378 43.6144C35.1378 41.9041 33.7332 40.5176 32.0005 40.5176C30.2679 40.5176 28.8633 41.9041 28.8633 43.6144C28.8633 45.3247 30.2679 46.7111 32.0005 46.7111Z'
            fill='white'
        />
    </svg>
);

class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            showConfirmBtn: false,
            isCountDown: false,
        };
        this.interval = undefined;
        this.timeDown = undefined;
        this.timeIdle = undefined;
        showMessageBox = showMessageBox.bind(this);
        showErrorBox = showErrorBox.bind(this);
        showWarningBox = showWarningBox.bind(this);
        showConfirm = showConfirm.bind(this);
        showSuccessBox = showSuccessBox.bind(this);
        showMessageBoxCountDown = showMessageBoxCountDown.bind(this);
        showMessageBoxIdleLogout = showMessageBoxIdleLogout.bind(this);
    }

    onClose = () => {
        this.setState({ open: false });
        if (this.state.onClose) {
            this.state.onClose();
        }
    };

    onConfirm = () => {
        try {
            this.setState({ open: !this.state.open });
            this.state.onConfirm();
        } catch (error) {
            
        }
    };
    startTimer = (duration, display) => {
        this.timeDown = duration;
        let minutes, seconds;
        this.interval = setInterval(() => {
            minutes = parseInt(this.timeDown / 60, 10);
            seconds = parseInt(this.timeDown % 60, 10);

            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            display.textContent = minutes + ':' + seconds;
            this.timeDown = this.timeDown - 1;
            if (this.timeDown === 0) {
                this.onClose();
                clearInterval(this.interval);
                if (this.state.isLogout) {
                    AuthenticationService.logoutExpiresToken();
                }
            }
        }, 1000);
    };
    countDownFc = (timeCountDown) => {
        if (counter === 0) {
            let display = document.querySelector('#timer');
            if (display) {
                this.timeDown = undefined;
                clearInterval(this.interval);
                this.interval = undefined;
                countDown = this.startTimer(timeCountDown, display);
                counter++;
            }
        } else {
            counter++;
        }
    };
    idleLogout = (waitingTime) => {
        window.onload = () => this.resetTimer(waitingTime);
        window.onmousemove = () => this.resetTimer(waitingTime);
        window.onmousedown = () => this.resetTimer(waitingTime); // catches touchscreen presses as well
        window.ontouchstart = () => this.resetTimer(waitingTime); // catches touchscreen swipes as well
        window.ontouchmove = () => this.resetTimer(waitingTime); // required by some devices
        window.onclick = () => this.resetTimer(waitingTime); // catches touchpad clicks as well
        window.onkeydown = () => this.resetTimer(waitingTime);
        window.addEventListener('scroll', () => this.resetTimer(waitingTime), true); // improved; see comments
    };
    yourFunction = () => {
        let messLockTime = (
            <p>
                Bạn đã lâu không thao tác,sau <span id='timer'></span> phút hệ thống sẽ tự thoát.Bạn
                có muốn tiếp tục không?
            </p>
        );
        showMessageBoxCountDown(
            messLockTime,
            120,
            async () => {
                showMessageBoxCountDown(messLockTime, 1, async () => {}, false);
            },
            async () => {
                await AuthenticationService.logoutExpiresToken();
            },
            true
        );
    };

    resetTimer = (waitingTime) => {
        clearTimeout(this.timeIdle);
        this.timeIdle = undefined;
        this.timeIdle = setTimeout(this.yourFunction, waitingTime * 60 * 1000); // time is in milliseconds
    };

    renderIcon(color) {
        if (color === 'modal-primary') {
            return MessageBoxSvg;
        }
        if (color === 'modal-danger') {
            return MessageErrorSvg;
        }
        if (color === 'modal-success') {
            return MessageSuccessSvg;
        }
        if (color === 'modal-warning') {
            return MessageWarningSvg;
        }
    }

    componentDidMount() {}

    render() {
        return (
            <div>
                <Modal
                    id={'messageBox'}
                    isOpen={this.state.open}
                    toggle={this.onClose}
                    backdrop='static'
                    className={`${this.state.color} messagebox`}
                >
                    {this.renderIcon(this.state.color)}
                    <ModalHeader toggle={this.onClose}>{this.state.title}</ModalHeader>
                    <ModalBody style={{ wordBreak: 'break-word' }}>{this.state.message}</ModalBody>
                    <ModalFooter className=''>
                        <Row className='w-100'>
                            {!this.state.showConfirmBtn && (
                                <div className='col-md-auto' hidden={this.state.showConfirmBtn}>
                                    <Button
                                        color='primary'
                                        id={'btnCloseMessage'}
                                        onClick={this.onClose}
                                    >
                                        <FTUTrans ns='common' name='msg.btnClose' />
                                    </Button>
                                </div>
                            )}

                            <div className='col-md-auto' hidden={!this.state.showConfirmBtn}>
                                <Button
                                    color='primary'
                                    id={'btnConfirmYes'}
                                    onClick={this.onConfirm}
                                >
                                    <FTUTrans ns='common' name='msg.btnYes' />
                                </Button>
                            </div>
                            <div className='col-md-auto' hidden={!this.state.showConfirmBtn}>
                                <Button id={'btnConfirmNo'} color='primary' onClick={this.onClose}>
                                    <FTUTrans ns='common' name='msg.btnNo' />
                                </Button>
                            </div>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default MessageBox;
