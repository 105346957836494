import {
    GET_BREADCRUMB,
    PATH_LOGIN,
    PATH_LOGOUT,
    PATH_REFRESH_TOKEN,
    POST_LOG_ACCESS,
    POST_LOG_ACCESS_PATH,
    URL_SSO_FRONT,
} from 'constants/AppPath';
import { APP_CODE } from 'constants/AppProps';
import { fetchUser } from 'reactRedux/action/userAction';
import store from 'reactRedux/store/store';
import { Utils } from 'services/common/utils';
import { jwtDecode } from "jwt-decode";

import Constants from '../../constants/Constants';
import React from 'react';
import { pushHistory } from '../../containers/app/App';
import {
    showErrorBox,
} from '../../components/MessageBox';
import { FTUTrans } from '../../components/FTUComponent';
import { hideProgress, showProgress } from '../../components/ProgressCustom';
import moment from 'moment';
import { DateUtils } from '../../utils/DateUtils';
import { GET_VALUE_BY_CODE } from '../../constants/AppPath';
import axios from 'axios';
import Cookies from 'js-cookie'
import KeycloakService from '../admin/KeycloakService';



export const AuthenticationService = {
    login,
    logout,
    logOutAll,
    isLogin,
    restoreUserInfo,
    logAccess,
    logAccessPath,
    getBreadcrumb,
    logoutExpiresToken,
    checkExpireToken,
    switchAccount,
};

export function authHeader(check = true) {
    try {
        if (!isLogin()) {
            logOutAll();
        }
        if (check) getRefreshToken();
    } catch (error) {
        console.error(error);
    }
    const currentUser = getObjectFromCookiesByPrefix('token');
    if (currentUser && currentUser.access_token) {
        return { Authorization: 'Bearer' + ' ' + currentUser.access_token };
    } else {
        return {};
    }
}


function switchAccount(data , isPopup = true) {
    var strJwt = Object.assign(
        data,
        _updateAccessTime(data.refresh_expires_in)
    );
    removeCookiesByPrefix('token' , {path : '/' , domain : window.globalConfig.domainPrefix})

    const popUp = window.open(
        window.globalConfig.urlBase +
            window.globalConfig.urlEndSessionEndpoint +
            window.location.origin +
            window.globalConfig.PUBLIC_URL +
            '/',
        'Logout' ,
        'width=300,height=300',
    )




    saveObjToCookies(strJwt , {path : '/' , domain : window.globalConfig.domainPrefix} , 'token')


    popUp.addEventListener('DOMContentLoaded' , () => {
        popUp.close()
        window.location.reload();
    })
}

async function login(username, password, func) {
    return Utils.postLogin(
        PATH_LOGIN,
        {
            username: username,
            password: password,
            appCode: APP_CODE,
        },
        function (data) {
            if (data && data.status && data.status === 200) {
                var strJwt = Object.assign(
                    data.data,
                    _updateAccessTime(data.data.accessTokenExpirationSecond)
                );

                saveObjToCookies(strJwt , {path : '/' , domain : window.globalConfig.domainPrefix} , 'token')
            }

            if (func) {
                func(data);
            }
        },
        true
    );
}

async function logoutExpiresToken() {
    removeCookiesByPrefix('token' , {path : '/' , domain : window.globalConfig.domainPrefix})
    window.location.assign(
        URL_SSO_FRONT +
            '/login?appCode=' +
            Constants.APP_ID +
            '&redirectUrl=' +
            removeTicketFromUrl() +
            '&expiresToken='
    );
}

// /**
//  * Đăng xuất KEY CLOAK + ADMIN
//  * */
async function logOutAll(consist = false) {
    await Utils.post(PATH_LOGOUT, {}, null, false , null , false )
    removeCookiesByPrefix('token' , {path : '/' , domain : window.globalConfig.domainPrefix})
    if (consist) {
        window.open(
            window.globalConfig.urlBase +
                window.globalConfig.urlEndSessionEndpoint +
                window.location.origin +
                "/" +
                (window.location.pathname+window.location.search).substr(1),

            '_self'
        );
    }else {
        window.open(window.globalConfig.urlBase + window.globalConfig.urlEndSessionEndpoint + window.location.protocol + '//' + window.location.host, '_self');
    }
}


function removeTicketFromUrl() {
    let url = new URL(window.location.href);
    let urlReturn = url.href;
    if (url.search.includes('&ticket=')) {
        let index = url.search.indexOf('&ticket=');
        let valueReplace = url.search.slice(index, url.search.length);
        urlReturn = url.href.replaceAll(valueReplace, '');
    }
    return urlReturn;
}



function isLogin() {
    const currentUser = getObjectFromCookiesByPrefix('token');;
    let nowTime = new Date().getTime();
    return (
        currentUser && currentUser.access_token && nowTime < currentUser.validTo
    );
}

function _updateAccessTime(accessExpireSeconds, waitingTime) {
    // var accessTime = {};
    // var nowTime = new Date();
    // nowTime.setSeconds(nowTime.getSeconds() + accessExpireSeconds);
    // accessTime['validTo'] = nowTime.getTime();
    // accessTime['waitingTime'] = waitingTime;

    // accessTime['timeBegin'] = moment().format(Constants.DATE_FORMAT_FULL);

    return {};
}

function restoreUserInfo() {
    const currentUser = AuthenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken) {
        store.dispatch(fetchUser(currentUser));
    }
}

function _checkRefresh() {
    const currentUser = getObjectFromCookiesByPrefix('token');
    if (currentUser && currentUser.timeBegin) {
        let nowTime = moment().format(Constants.DATE_FORMAT_FULL);
        let timeBegin = currentUser.timeBegin

        let timeLife = parseInt(currentUser.expires_in/60)
        let diffTimeRefreshToken = Number(window.globalConfig.diffTimeRefreshToken || 30);
        if (!localStorage.getItem("REFRESH_TOKEN_GAP_TIME")) {
            const requestOptions = {headers: authHeader(false)};
            let url = Utils._urlRender(GET_VALUE_BY_CODE, {code : "REFRESH_TOKEN_GAP_TIME"}, true, false);
            const diffTimeParam = axios.get(url , requestOptions);

            diffTimeParam.then(({data}) => {
                if (data?.value && data.status === 1) {
                    diffTimeRefreshToken = data.value;
                    localStorage.setItem("REFRESH_TOKEN_GAP_TIME" , data.value)
                }
            })
        }else {
            diffTimeRefreshToken = Number(localStorage.getItem("REFRESH_TOKEN_GAP_TIME"))
        }


        let diffTime = DateUtils.dateDiff(
            nowTime,
            timeBegin,
            Constants.DATE_FORMAT_FULL,
            'minutes'
        );

        return diffTimeRefreshToken >= timeLife - diffTime;
    }
    return false;
}

async function getRefreshToken() {
    if (_checkRefresh()) {
        try {
            const service = new KeycloakService()
            const token = getObjectFromCookiesByPrefix('token');
            let redirectUrl =
                window.location.protocol + '//' + window.location.host;
            let res = await service.getTokenByRF({
                refreshToken : token.refreshToken,
                redirectUri : redirectUrl
            })

            if (res.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
                let dataGetToken = res.data.data
                let strJwt = Object.assign(
                    dataGetToken,
                    _updateAccessTime(dataGetToken.expires_in)
                );

                saveObjToCookies(strJwt , {path : '/' , domain : window.globalConfig.domainPrefix} , 'token')

            }
        } catch (err) {
            console.error(err);
        }
    }
}
function checkExpireToken() {
    const currentUser = getObjectFromCookiesByPrefix('token');
    if (!currentUser?.access_token) {
        return false
    }
    let nowTime = new Date().getTime();
    return nowTime >= currentUser.validTo;
}

function logAccess(id) {
    Utils.post(POST_LOG_ACCESS, { moduleId: id });
}

function logAccessPath(path) {
    if (path && path !== '/admin/') {
        let menu = localStorage.getItem('currentMenu');
        if (path && menu) {
            let pathCompare = path.replace('/admin', '');
            let menuPermission = JSON.parse(menu);
            let check = findMenuPermission(menuPermission, pathCompare);
            if (check) {
                Utils.post(POST_LOG_ACCESS_PATH, {
                    appCode: APP_CODE,
                    path: path,
                });
            } else {
                pushHistory('/admin/');
                setTimeout(
                    () =>
                        showErrorBox(
                            <FTUTrans ns='common' name='error.common403' />
                        ),
                    0
                );
            }
        }
    }
}

function getBreadcrumb(data) {
    return Utils.get(GET_BREADCRUMB, data, null, true);
}

function findMenuPermission(menu, path) {
    let value = false;
    if (menu && menu.length > 0) {
        for (let i = 0; i < menu.length; i++) {
            if (menu[i].to && menu[i].to.includes(path)) {
                value = true;
            }
            if (value == false && menu[i].items.length > 0) {
                value = findMenuPermission(menu[i].items, path);
            }
        }
    }
    return value;
}


export function saveObjToCookies (obj = {} , options = {} , prefix = "") {
    try {
        Cookies.set('access_token' , obj['access_token'] ,options)
        Cookies.set('refreshToken' , obj['refresh_token'] ,options)
    } catch (error) {

    }
}

export function getObjectFromCookiesByPrefix(prefix = '') {
    try {
        const obj = {};
        const accessToken = Cookies.get('access_token');
        const refreshToken = Cookies.get('refreshToken');
        obj.access_token = accessToken
        obj.refreshToken = refreshToken
        if (accessToken) {
            const token = jwtDecode(accessToken);
            obj.validTo = token.exp * 1000;
            obj.timeBegin = moment.unix(token.iat).format(Constants.DATE_FORMAT_FULL);
            obj.expires_in = token.exp - token.iat;
        }

        if (refreshToken) {
            const refresh = jwtDecode(refreshToken);
            obj.refresh_expires_in = refresh.exp * 1000;
        }

        return obj;
    } catch (error) {
        return {};
    }
}

export function removeCookiesByPrefix (prefix , options = {} , clear = true) {
    try {
        Cookies.remove('access_token' , options);
        Cookies.remove('refreshToken' , options);
        localStorage.clear()

    } catch (error) {
    }
}

async function logout(params) {

}
