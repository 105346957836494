import { Menu } from "primereact/menu";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { AuthenticationService } from "services/access/AuthenticationService";
import { hideDialog, showDialog } from "../../components/Dialog";
import { FTUComponent } from "../../components/FTUComponent";
import Constants from "../../constants/Constants";
import { NotifyHistoryService } from "../../services/admin/NotifyHistoryService";
import ChangePassword from "../admin/changePassword/ChangePassword";
import DetailNotify from "./DetailNotify";

import { Divider } from "antd";
import { jwtDecode } from "jwt-decode";
import { showErrorBox } from "../../components/MessageBox";
import { URL_SIMULATOR } from "../../constants/AppPath";
import { getObjectFromCookiesByPrefix } from "../../services/access/AuthenticationService";
import UserInfoService from "../../services/admin/UserInfoService";
import { Utils } from "../../services/common/utils";
import ICON from "./IconSVG";
import "./navbar.scss";
import NavSystemIcon from "./NavSystemIcon";
import SwitchAccountDetailForm from "./SwitchAccountDetailForm";
import { ApplicationListService } from "../../services/admin/ApplicationListService";

const _ = require("lodash");
const {SubSystemStatus: {ON}} = Constants
const mapStateToProps = (state) => ({
  user: state.user.info,
});

const {
  SERVICE_CODE: { SUCC_CODE },
} = Constants;

class AppTopbar extends FTUComponent {

  app = new ApplicationListService();

  _isMounted = false;
  componentWillUnmount() {
    this._isMounted = false;
  }

  onGetAppItem = async () => {
    let res = await this.app.getAllItem();

    if (res && Array.isArray(res?.data)) {
      this.setState({
        appItem: res.data.filter((ele) => {
          return ele.status === ON;
        }),
      });
    }
  };

  componentDidMount = async () => {

    try {
      const url = new URL(window.location.href)

      if (url.pathname !== '/') {
        this.onGetAppItem()
      }
    } catch (error) {

    }

    try {
      let userStorage = JSON.parse(localStorage.getItem("userBasicInfo"));
      let token = getObjectFromCookiesByPrefix("token");

      if (token?.access_token !== undefined) {
        const tokenDecode = jwtDecode(token.access_token);
        if (
          !userStorage?.userName ||
          !userStorage.jobTitles?.positionCode ||
          userStorage?.userId !== tokenDecode?.sub
        ) {
          const userInfo = await this.service.getInfoUserBasic();

          if (userInfo?.data.code === Constants.SERVICE_CODE.SUCC_CODE) {
            const userName = userInfo?.data.data.username;
            const jobTitle = userInfo?.data.data;
            const jobTitleString = `${jobTitle?.positionCode} ― ${jobTitle?.positionName}`;
            const orgCodeString = `${jobTitle?.orgCode} ― ${jobTitle?.orgName}`;

            localStorage.setItem(
              "userBasicInfo",
              JSON.stringify({
                userName: userName,
                userId: userInfo?.data?.data?.userId,
                jobTitles: jobTitle,
                companyLogo: userInfo?.data.data.companyLogo,
              })
            );

            this.setState({
              userName: userName,
              jobTitles: jobTitleString,
              orgCode: orgCodeString,
              companyLogo: jobTitle?.logoCompany,
            });
          }
        } else {
          this.setState({
            userName: userStorage.userName,
            jobTitles:
              userStorage?.jobTitles?.positionCode &&
              userStorage?.jobTitles?.positionName
                ? `${userStorage?.jobTitles?.positionCode} ― ${userStorage?.jobTitles?.positionName}`
                : undefined,
            orgCode:
              userStorage?.jobTitles?.orgCode && userStorage?.jobTitles?.orgName
                ? `${userStorage?.jobTitles?.orgCode} ― ${userStorage?.jobTitles?.orgName}`
                : undefined,
            companyLogo: userStorage?.jobTitles?.logoCompany,
          });
        }
      }
    } catch (error) {
      this.commonHandleError(error);
    }
    this._isMounted = true;
    this.waitRedux().then(() => this.forceUpdate());

    this.memo = performance.now();
  };

  callBackFunction = (countNotify) => {
    this.setState({
      countNotify: countNotify,
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      dataNotify: [],
      dataNotSeen: [],
      countNotify: 0,
      isShowSystem: false,
    };

    this.service = new UserInfoService();
    this.notifyService = new NotifyHistoryService();
  }
  onGetNotify = async () => {
    let rs = await this.notifyService.getNotifyByUser();
    if (rs && rs.data && rs.data.data) {
      let data = rs.data.data;
      let dataNoSeen = _.filter(data, function (o) {
        return (
          (o.status ? Number(o.status) : null) === Constants.NOTIFY.NO_SEEN
        );
      });
      this.setState({
        dataNotify: rs.data.data,
        dataNotSeen: dataNoSeen,
        countNotify: dataNoSeen.length,
      });
    }
  };
  changePassword = (e) => {
    showDialog(
      <ChangePassword options={{}} />,
      this.trans("changePassword:changePassword"),
      {
        maxWidth: "40%",
      }
    );
  };
  onOpenDetailNotify = () => {
    showDialog(
      <DetailNotify
        options={{
          dataNotify: this.state.dataNotify,
          dataNoSeen: this.state.dataNotSeen,
          parentCallBack: (countNotify) => {
            this.callBackFunction(countNotify);
          },
          onComplete: () => {
            hideDialog(false);
          },
          onCancel: () => {
            hideDialog(false);
          },
        }}
      />,
      this.trans("notifyHistory:notifyTitle"),
      { maxWidth: "80%", top: "15%" }
    );
  };

  goToUserInfo = (e) => {
    if (!this.props.hiddenMenu) {
      window.open(window.location.origin + "/userInfo", "_blank");
    }
  };

  goToChangePassword = (e) => {
    window
      .open(
        window.location.origin +
          window.globalConfig.PUBLIC_URL +
          "userInfo?password=true",
        "_blank"
      )
      .focus();
  };

  logout = (e) => {
    AuthenticationService.logOutAll(true);
  };

  onSwitchAccount = () => {
    showDialog(
      <SwitchAccountDetailForm
        options={{
          data: null,
          dataNew: null,
          onCancel: () => {
            hideDialog();
          },
        }}
      />,
      this.trans("common:menu.ChangeAccount"),
      { minHeight: "80%" }
    );
  };
  onSwitchAccountPage = async () => {
    // Other subsystem backend domain
    const res = await Utils.getByUrl(`${URL_SIMULATOR}/switch/code-switch-acc`);

    if (res.data && res.data.code === SUCC_CODE) {
      window.location.replace(
        `${window.location.origin}/switchAccount?redirect_url=${window.location.origin}&code=${res.data.data}`
      );
    } else {
      showErrorBox(this.trans("common:error.common"));
    }
  };
  onReadNoti = () => {
    this.setState({
      count: 0,
    });
  };
  render() {
    let logoSrcFallback = "/assets/layout/images/logo_dxg.svg";
    const logoSrc = this.state.companyLogo ?? "Error";
    let jobTitles = !!this.state.jobTitles ? this.state.jobTitles : "Chức vụ";
    let orgCode = !!this.state.orgCode ? this.state.orgCode : "Phòng ban";
    let userName = this.state.userName;
    let itemUserInfo = [
      {
        label: userName,
        items: [
          {
            label: this.trans("common:menu.UserInfo"),
            icon: "pi pi-fw pi-info",
            command: this.goToUserInfo,
          },
          {
            label: this.trans("common:app.resetPassword"),
            icon: "pi pi-fw pi-pencil",
            command: this.goToChangePassword,
          },
          {
            label: this.trans("common:menu.ChangeAccount"),
            icon: "pi pi-fw pi-users",
            command: this.onSwitchAccount,
          },
          {
            label: this.trans("common:app.logout"),
            icon: "pi pi-fw pi-power-off",
            command: this.logout,
          },
        ],
      },
    ];

    return (
      <nav
        style={this.props?.style ?? {}}
        className="navbar"
        id={"navbar-new-ver"}
      >
        <div className="navbar__left">
          <div className={"btn-dropdown"}>
            <NavSystemIcon
              appItem={this.props.appItem ?? this.state.appItem}
              onClickItemApp={this.props.onClickItemApp}
            />
            {/*<img src={'/assets/layout/images/icon_navbar.svg'}/>*/}
          </div>
          <Divider type="vertical" />
          <NavLink to={this.props.path}>
            <img
              src={`${logoSrc}?random=${this.memo}`}
              className="small-company-logo"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = logoSrcFallback;
              }}
            />
          </NavLink>
        </div>

        {!this.props.title && (
          <div className={"box-center"}>
            <h3 style={{ fontSize: "24px", color: "#0564AB", margin: 0 }}>
              HỆ THỐNG ERP TẬP ĐOÀN ĐẤT XANH
            </h3>
          </div>
        )}
        <div className="navbar__right">
          <div
            className={`navbar__right--title typo__title--2 ${
              this.props.title ? "" : "hidden"
            }`}
            style={{
              letterSpacing: ".3px",
            }}
          >
            {this.trans("common:app.module")}
          </div>

          <div className="navbar__right--profile disabled-line">
            <div className="navbar__right--profile">
              {!this.props?.hiddenMenu && (
                <Menu
                  model={itemUserInfo}
                  popup={true}
                  className={"top-right-menu"}
                  style={{ width: "165px", fontSize: "12px" }}
                  ref={(el) => (this.menu = el)}
                />
              )}
              <div className="profile__dropdown">
                <button
                  disabled={this.props?.hiddenMenu}
                  onClick={(event) => this.menu.toggle(event)}
                >
                  {ICON.ArrowDownSVG}
                </button>
              </div>

              <div
                className="profile__label"
                onClick={this.goToUserInfo}
                style={{ cursor: "pointer" }}
              >
                <div className="profile__label--text">
                  <div className="name d-flex align-items-center">
                    <div>{userName}</div>
                  </div>
                  <div
                    className="position d-flex align-items-center"
                    style={{ height: "30%" }}
                  >
                    <div>{orgCode}</div>
                  </div>

                  <div
                    className="position d-flex align-items-center"
                    style={{ height: "30%" }}
                  >
                    <div>{jobTitles}</div>
                  </div>
                </div>
                <div className="profile__label--avatar">
                  <img
                    src="/assets/layout/images/avatar-svgrepo-com.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

AppTopbar.defaultProps = {
  onToggleMenu: null,
  onChangeLgn: null,
  appLgn: "vi",
  title: true,
};

AppTopbar.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
  title: PropTypes.bool,
};

export default withTranslation(["changePassword", "common", "notifyHistory"])(
  connect(mapStateToProps)(AppTopbar)
);
